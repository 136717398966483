import { React, useState } from 'react'; // forwardRef, Fragment
import { useSelector } from 'react-redux';
// import { TableVirtuoso } from 'react-virtuoso';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
// import InputAdornment from '@mui/material/InputAdornment';
// import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import { visuallyHidden } from '@mui/utils';

import { CircularProgress } from '@mui/material';

import { useGetDatasetsQuery, useGetFilesQuery } from '../../state/apiSlice';

// TODO: add support for other indexes than FileID, also in API.
// Will ordering be implicit? Or is there any situations where we would not want it?
// Direction will not at least

function FetchAndDisplayFiles(props) {
    const { id } = props;

    const [page, setPage] = useState(0);
    const [index, setIndex] = useState(undefined);
    const [direction, setDirection] = useState('next');
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [orderBy, setOrderBy] = useState('FileID');
    const [sortDir, setSortDir] = useState('asc');

    const resetPagination = () => {
        setIndex(undefined);
        setDirection('next');
        setPage(0);
    };

    const handleChangePage = (e, newPage) => {
        if (newPage - page > 0) {
            setIndex(data ? data.pagination.next_page.index : 0);
            setDirection(data ? data.pagination.next_page.direction : 'next');
        } else {
            setIndex(data ? data.pagination.prev_page.index : 0);
            setDirection(data ? data.pagination.prev_page.direction : 'prev');
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(+e.target.value);
        resetPagination();
    };

    const handleSearchFieldWrite = (e) => {
        if (e.target.value) {
            setSearchPhrase(`%${e.target.value}%`);
        } else {
            setSearchPhrase('%');
        }
    };

    const createSortHandler = (column) => (e) => {
        let dir;
        if (orderBy === column) {
            dir = sortDir === 'asc' ? 'desc' : 'asc';
        } else {
            dir = 'asc';
        }
        setSortDir(dir);
        setOrderBy(column);
        resetPagination();
    };

    const performSearch = () => {
        setFilterBy(searchPhrase);
        resetPagination();
    };

    const [searchPhrase, setSearchPhrase] = useState('%');
    const [filterBy, setFilterBy] = useState('%');

    const { data, error, isError, isSuccess, isFetching } = useGetFilesQuery({
        id,
        params: {
            index,
            limit: rowsPerPage,
            filter: filterBy,
            direction,
            sort_direction: sortDir,
            order_by: orderBy
        }
    });

    const columns = [
        {
            width: '10%',
            label: 'ID',
            dataKey: 'FileID'
        },
        {
            width: '50%',
            label: 'Name',
            dataKey: 'MFileName'
        },
        {
            width: '20%',
            label: 'Entries',
            dataKey: 'MetricEntries'
        },
        {
            width: '20%',
            label: 'Size',
            dataKey: 'FileSize'
        }
    ];

    function fixedHeaderContent() {
        return (
            <TableRow>
                {columns.map((column) => (
                    <TableCell
                        key={column.dataKey}
                        variant="head"
                        align='center'
                        style={{ width: column.width }}
                    >
                        <TableSortLabel
                            active={orderBy === column.dataKey}
                            direction={orderBy === column.dataKey ? sortDir : 'asc'}
                            onClick={createSortHandler(column.dataKey)}
                            >
                            <div overflow='hidden'>
                                {column.label}
                            </div>
                            {/* <Typography variant='body' style={{ overflow: 'auto', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                {column.label}
                            </Typography> */}
                            {orderBy === column
                                ? (
                                    <Box component='span' sx={visuallyHidden}>
                                        {sortDir === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                )
                                : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        );
    }

    let content = <></>;
    let tableContent = <></>;

    if (isFetching) {
        content = <>
            <CircularProgress sx={{ padding: 2 }} />
            <Typography align='center' sx={{ paddingBottom: 2 }}>
                Loading files...
            </Typography>
        </>;
    } else if (isSuccess) {
        if (data.files.length === 0) {
            content = <Typography align='center' sx={{ padding: 2 }}>
                No files found
            </Typography>;
        } else {
            tableContent = <TableBody>
                {data.files.map((file) => {
                    return <TableRow key={file.FileID}>
                        {columns.map((column) => {
                            return <TableCell
                                key={column.dataKey}
                                align='center'
                                >
                                {file[column.dataKey] || '-'}
                            </TableCell>;
                        })}
                    </TableRow>;
                })}
            </TableBody>;
        }
    } else if (isError) {
        console.error('Failed to fetch files: ', error);
        content = <Typography color='text.error' align='center' sx={{ padding: 2 }}>
            Failed to fetch files
        </Typography>;
    }

    // 'max-height': 1000, 'min-height': 100,
    return <>
        <TextField
            id='input-with-icon-textfield'
            label=''
            InputProps={
                {
                    endAdornment: (
                        <Button variant='outlined'
                            startIcon={<SearchIcon />}
                            onClick={performSearch} >
                            search
                        </Button>
                    ),
                    placeholder: 'Filter by file name'
                }
            }
            onInput={handleSearchFieldWrite}
            onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        performSearch();
                    }
                }
            }
            fullWidth
            margin='dense'
            variant='outlined'
            />
        <Paper>
            <TableContainer sx={{ maxHeight: 1000 }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead >
                        {fixedHeaderContent()}
                    </TableHead>
                    {tableContent}
                </Table>
            </TableContainer>
            {content}
            <TablePagination
                rowsPerPageOptions={[10, 25, 100, 500]}
                component="div"
                count={data ? data.pagination.total_records : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    </>;
}

function FetchAndDisplayExtraFiles(props) {
    const { id } = props;

    const { data, error, isError, isSuccess, isFetching } = useGetFilesQuery(
        { id, params: { limit: 0, extrafiles: true } }
    );

    const columns = [
        {
            width: '10%',
            label: 'ID',
            dataKey: 'FileID'
        },
        {
            width: '50%',
            label: 'Name',
            dataKey: 'MFileName'
        },
        {
            width: '20%',
            label: 'Entries',
            dataKey: 'MetricEntries'
        },
        {
            width: '20%',
            label: 'Size',
            dataKey: 'FileSize'
        }
    ];

    function fixedHeaderContent() {
        return (
            <TableRow>
                {columns.map((column) => (
                    <TableCell
                        key={column.dataKey}
                        variant="head"
                        align='center'
                        style={{ width: column.width }}
                    >
                        <div overflow='hidden'>
                            {column.label}
                        </div>
                    </TableCell>
                ))}
            </TableRow>
        );
    }

    let content = <></>;
    let tableContent = <></>;

    if (isFetching) {
        content = <>
            <CircularProgress sx={{ padding: 2 }} />
            <Typography align='center' sx={{ paddingBottom: 2 }}>
                Loading files...
            </Typography>
        </>;
    } else if (isSuccess) {
        if (data.files.length === 0) {
            content = <Typography align='center' sx={{ padding: 2 }}>
                No files found
            </Typography>;
        } else {
            tableContent = <TableBody>
                {data.files.map((file) => {
                    return <TableRow key={file.FileID}>
                        {columns.map((column) => {
                            return <TableCell
                                key={column.dataKey}
                                align='center'
                                >
                                {file[column.dataKey] || '-'}
                            </TableCell>;
                        })}
                    </TableRow>;
                })}
            </TableBody>;
        }
    } else if (isError) {
        console.error('Failed to fetch files: ', error);
        content = <Typography color='text.error' align='center' sx={{ padding: 2 }}>
            Failed to fetch files
        </Typography>;
    }

    // 'max-height': 1000, 'min-height': 100,
    return <>
        <Paper>
            <TableContainer sx={{ maxHeight: 1000 }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead >
                        {fixedHeaderContent()}
                    </TableHead>
                    {tableContent}
                </Table>
            </TableContainer>
            {content}
        </Paper>
    </>;
}

FetchAndDisplayFiles.propTypes = {
    id: PropTypes.number.isRequired
};

FetchAndDisplayExtraFiles.propTypes = {
    id: PropTypes.number.isRequired
};

// Add second table for extrafiles
export default function Files() {
    const {
        data: datasets,
        isSuccess,
        isError,
        error
    } = useGetDatasetsQuery();

    const selectedID = useSelector(state => state.dataset.selectedID);

    let content = <>
        <CircularProgress sx={{ m: 2 }} />
        <Typography align='center'>
            Fetching datasets...
        </Typography>
    </>;
    if (isSuccess) {
        const ds = datasets.filtered.find(ds => ds.DatasetID === selectedID);
        if (ds) {
            content = <>
                <Typography variant='h4' gutterBottom>
                    Browse the data files in {ds.DatasetName}
                </Typography>
                <FetchAndDisplayFiles
                    key={'fetchanddisplay-files-' + selectedID}
                    id={selectedID}
                    />
                <Typography variant='h4' gutterBottom sx={{ paddingTop: 2 }}>
                    Additional files
                </Typography>
                <FetchAndDisplayExtraFiles
                    key={'fetchanddisplay-extrafiles-' + selectedID}
                    id={selectedID}
                    />
            </>;
        } else {
            content = <Typography color='text.error' align='center'>You have no available datasets</Typography>;
        }
    } else if (isError) {
        console.error('Failed to fetch files: ', error);
        content = <Typography color='text.error' align='center'>Failed to fetch files</Typography>;
    }
    return content;
}
